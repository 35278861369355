import React, { Component } from "react"
import axios from 'axios';
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import mail from "../images/mail.svg"
import padlockOpen from "../images/padlock-open.svg"
import { AUTH_TOKEN_KEY, PARAM_CARD_NMBRE, DOMAIN_API, login, URL_LOGIN, translate } from "../utils"

class SignUp extends Component {
  state = {
    cardNmbre: '',
    email1: '',
    email2: '',
    password1: '',
    password2: '',
    placeholderPassword: '',
  }

  componentDidMount() {
    this.setState({
      placeholderPassword: translate('wachtwoord')
    })


    if (this.props.location) {
      const query = new URLSearchParams(this.props.location.search);
      if (query.get(PARAM_CARD_NMBRE) !== null && (query.get(PARAM_CARD_NMBRE) !== 'false')) {
       
        this.setState({cardNmbre: query.get(PARAM_CARD_NMBRE)})
      }else {
        console.log(`${PARAM_CARD_NMBRE} not found from query`)
      }
    }
  }

  render() {
    const {cardNmbre,email1,email2,password1,password2} = this.state;

    const pressSignUp = () => {
      if (email1 !== email2) return alert(translate('emails do not match'))
      if (password1 !== password2) return alert(translate('password do not match') )
      const data = {
        email: email1,
        password: password1,
        ncrd_num: cardNmbre
      }
      //AVOID FOR DESIGN SIGNUP FLOW

      /*const url = DOMAIN_API() + `/user/${(cardNmbre !== '')?'signupWCrndnbr':'signupNoCrndnbr'}`
      axios.post(url,data)
        .then(res => {
          console.log(res.data)
          axios.post(URL_LOGIN,data)
            .then(res => {
              console.log('TOKEN :',res.data)
              sessionStorage.setItem(AUTH_TOKEN_KEY,res.data)
              window.location.href = '/feed';
            })
            .catch(err => {
              if (err.response) {
                console.log(err.response.data);
                alert(err.response.data.message)
              }
              console.log(err)
            })
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
            alert(err.response.data.message)
          }
          alert(err)
        });*/

    }

    return (
      <Layout withBg>
        <div className="formWrapper">
          <Container>
            <Row className="justify-content-center">
              <Col lg="7">
                <div className="text-left">
                  <h2 className="mb-4">{translate('uw account aanmaken')}</h2>
                  <Form>
                    <Row>
                      <Col md="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <img
                                width={16}
                                height={8}
                                className="img-fluid"
                                src={mail}
                                alt="mail"
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            value={email1}
                            onChange={(e) => this.setState({email1: e.target.value})}
                            placeholder="E-mail"
                            aria-label=""
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <img
                                width={16}
                                height={8}
                                className="img-fluid"
                                src={mail}
                                alt="mail"
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            value={email2}
                            onChange={(e) => this.setState({email2: e.target.value})}
                            placeholder="E-mail"
                            aria-label=""
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <img
                                width={11}
                                height={17}
                                className="img-fluid"
                                src={padlockOpen}
                                alt="mail"
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type={'password'}
                            value={password1}
                            onChange={(e) => this.setState({password1: e.target.value})}
                            placeholder={this.state.placeholderPassword}
                            aria-label=""
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <img
                                width={11}
                                height={17}
                                className="img-fluid"
                                src={padlockOpen}
                                alt="mail"
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type={'password'}
                            value={password2}
                            onChange={(e) => this.setState({password2: e.target.value})}
                            placeholder={this.state.placeholderPassword}
                            aria-label=""
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Form.Group
                      controlId="formBasicCheckbox"
                      className="d-none d-md-flex justify-content-center mt-3"
                    >
                      <Form.Check
                        className="text-certer"
                        type="checkbox"
                        label={translate("onthoud mij")}
                      />
                    </Form.Group>
                    <div className="d-md-flex justify-content-center mt-3 form-group row">
                    <Button variant="success" onClick={() => pressSignUp()} className=" button-yellow">
                      {translate('sign Up')}
                    </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }

}

export default SignUp
